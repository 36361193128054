import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Preloader } from './components/ui/preloaders/mainloader';
import BlogSite from './components/blog';
import JobRoles from './components/forms/offers';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={
      <Preloader/>
    }>
      <Router>
        <Switch>
          <Route path="/" exact component ={()=><App/>}/>
          <Route path="/blog" exact component ={BlogSite} />
          <Route path="/memphiscorp/hr/open-roles" exact component ={JobRoles}/>
        </Switch> 
      </Router>  
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


