import React, { useState } from "react";
import "./styles/landing.css";

//general page icons
import codeimg from "../assets/icons/general/Source-Code.svg";
import wooferblack from "../assets/icons/general/Subwoofer.svg";
import wooferwhite from "../assets/icons/general/Subwoofer-white.svg";
import rocket from "../assets/icons/general/Rocket.svg";
import musicico from "../assets/icons/general/Music.svg";
import developer from "../assets/icons/general/Developer.svg";
import musicico_white from "../assets/icons/general/Music-white.svg";
import developer_white from "../assets/icons/general/Developer-white.svg";
import moon from "../assets/icons/general/Moon-Man.svg";
import sun from "../assets/icons/general/Sun.svg";
import likeicon from "../assets/icons/general/Heart.svg";

//social icons
import mailico from "../assets/icons/socials/Mail.svg";
import linkedinico from "../assets/icons/socials/LI-In-Bug.png";
import fbico from "../assets/icons/socials/Facebook.svg";
import instico from "../assets/icons/socials/Instagram.svg";
import twtico from "../assets/icons/socials/Twitter.svg";

function LandingPage({ logo, lightmode, setLightmode }) {
  const [pageVisits] = useState(145);
  let [likes, setLikes] = useState(134);

  //likes function
  function likedMemphis() {
    setLikes(likes + 1);
  }
  //function to toggle dark mode
  function toggleDarkmode() {
    setLightmode(!lightmode);
  }

  //social links
  const sociallinks = [
    {
      id: "1",
      route: "mailto:memphiscorporations@gmail.com",
      class: "ml",
      ico: mailico,
      alttext: "mail",
      title: "Mail us",
    },
    {
      id: "2",
      route: "https://facebook.com/memphishub",
      class: "fb",
      ico: fbico,
      alttext: "faceb",
      title: "Facebook",
    },
    {
      id: "3",
      route: "https://www.instagram.com/hub_memphis",
      class: "ins",
      ico: instico,
      alttext: "Inst",
      title: "Instagram",
    },
    {
      id: "4",
      route: "https://www.linkedin.com/company/memphiscorp",
      class: "li",
      ico: linkedinico,
      alttext: "LI",
      title: "LinkedIn",
    },
    {
      id: "5",
      route: "https://x.com/hub_memphis",
      class: "twt",
      ico: twtico,
      alttext: "Twi",
      title: "Twitter",
    },
  ];
  return (
    <body>
      <div
        className={
          lightmode === true
            ? "landing-container-lightmode"
            : "landing-container-darkmode"
        }
      >
        <div className="top-section">
          <div className="left-section">
            <span className="left-icon">
              {lightmode ? (
                <img src={musicico} alt="music-icon" />
              ) : (
                <img src={musicico_white} alt="music-icon" />
              )}
            </span>
            <span className="left-icon">
              {lightmode ? (
                <img src={developer} alt="tech-icon" />
              ) : (
                <img src={developer_white} alt="tech-icon" />
              )}
            </span>
          </div>
          <div className="right-section">
            <button
              className={
                lightmode === true
                  ? "right-icon-lightmode"
                  : "right-icon-darkmode"
              }
              onClick={toggleDarkmode}
            >
              {lightmode === true ? (
                <img src={moon} alt="dark-mode" />
              ) : (
                <img src={sun} alt="light-mode" />
              )}
            </button>
          </div>
        </div>
        <div className="middle-section">
          <span className="rocket">
            <img src={rocket} alt="rocket" />
          </span>
          <div className="logo-group">
            <span className="organization-division software">
              <img src={codeimg} alt="code-img" />
            </span>
            <span className="logo-container">
              <img src={logo} alt="memphis-logo-trademarked" />
            </span>
            <span className="organization-division music">
              {lightmode === true ? (
                <img src={wooferblack} alt="woofer-img" />
              ) : (
                <img src={wooferwhite} alt="woofer-img" />
              )}
            </span>
          </div>
          <span
            className={
              lightmode === true
                ? "slogan slogan-lightmode"
                : "slogan slogan-darkmode"
            }
          >
            Design, Invent, the standard...
          </span>
        </div>
        <div className="bottom-section">
          <div className="socials-section">
            {sociallinks.map((social) => (
              <a
                key={social.id}
                href={social.route}
                className={social.class}
                title={social.title}
                target="_blank"
                rel="noreferrer"
              >
                <img src={social.ico} alt={social.alttext} />
                <span>{social.title}</span>
              </a>
            ))}
          </div>
          <div className="like">
            {/* <span>Unique Page visits: {pageVisits}</span> */}
            <button
              className="like-btn"
              title="Give us a like? Thank you 😊"
              onClick={likedMemphis}
            >
              <img src={likeicon} alt="like-ico" className="bounce" />
            </button>
            <span
              className={
                lightmode === true
                  ? "slogan slogan-lightmode"
                  : "slogan slogan-darkmode"
              }
            >
              {likes}
            </span>
          </div>
        </div>
      </div>
    </body>
  );
}

export default LandingPage;
