import React, { useState } from "react";
import "../styles/jobroles.css";
import trademark from "../../assets/images/logo-soft-edges-b.svg";
import projectTEam from "../../assets/illustrations/DrawKit-Vector-Illustration-Project-Manager-1.svg";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaCaretRight,
  FaMapPin,
} from "react-icons/fa";
import AllRoles from "./dummydata.json";

function JobRoles() {
  const [showJobDetail, setShowJobDetail] = useState(false);
  return (
    <div className="roles-container">
      <div className="header-section">
        <a href="/" className="logo-anchor">
          <img src={trademark} alt="Memphis Corp" />
        </a>

        <div>
          <button className="header-actions" type="button">
            Contact
          </button>
          <a href="#" className="header-actions">
            Corporate site
          </a>
        </div>
      </div>

      <div className="jobs-section">
        <div className="section-title">
          <h3>Open positions</h3>
        </div>
        <div className="content-section">
          <div className="job-list">
            <h4>All jobs</h4>
            {AllRoles.map((job, index) => {
              return (
                <div
                  className="job-item"
                  id="job"
                  key={index}
                  title={`${job.scope} ${job.id} ${job.title}. Click to view details.`}
                  onClick={()=>{setShowJobDetail(true)}}
                >
                  <span className="title">{job.title} </span>
                  <span className="department">{job.scope}</span>
                  <span className="location">
                    <FaMapPin />
                    &nbsp;{job.location}
                  </span>
                  <span className="indicatior">
                    <button className="show-details">
                      <FaCaretRight />
                    </button>
                  </span>
                </div>
              );
            })}
          </div>

          {showJobDetail ? (
            <div className="job-detail">
              <h4>Details</h4>
              <div className="job-content"></div>
            </div>
          ) : (
            <div className="illustration">
              <img src={projectTEam} alt="illustration" />
            </div>
          )}
        </div>
      </div>
      <div className="footer-section">
        <div className="terms-privacy">Terms and Conditions</div>
        <div className="copy-right-holder">&copy; Memphis, Corp. - 2022</div>
        <div className="socials">
          <a
            href="tel:+233500590639"
            target="__blank"
            className="media-item mail-ico"
          >
            <FaPhoneAlt className="phone-ico" />
          </a>
          <a
            href="mailto:memphiscorporations@gmail.com"
            target="__blank"
            className="media-item mail-ico"
          >
            <FaEnvelope className="mail-ico" />
          </a>
          <a
            href="https://wa.me/233500590639"
            target="__blank"
            className="media-item whatsapp-ico"
          >
            <FaWhatsapp className="whatsapp-ico" />
          </a>
          <a
            href="https://web.facebook.com/memphisinc7"
            target="__blank"
            className="media-item facebook-ico"
          >
            <FaFacebook className="facebook-ico" />
          </a>
          <a
            href="https://www.instagram.com/hub_memphis/"
            target="__blank"
            className="media-item instagram-ico"
          >
            <FaInstagram className="instagram-ico" />
          </a>
          <a
            href="https://twitter.com/hub_memphis"
            target="__blank"
            className="media-item twitter-ico"
          >
            <FaTwitter className="twitter-ico" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default JobRoles;
